import * as Sentry from '@sentry/react';
import { env } from './env';

interface InitSentryOptions extends Sentry.BrowserOptions {
  environment?: string;
}

/**
 * 屏蔽不上报规则
 * @returns
 */
const maskReport = (): boolean => {
  const hostnames = ['localhost', '127.0.0.1'];
  if (hostnames.includes(window.location.hostname)) {
    return true;
  }
  return false;
};

/**
 * 初始化 Sentry
 */
export const setupSentry = (options?: InitSentryOptions) => {
  if (!maskReport()) {
    const _options: Sentry.BrowserOptions = {
      environment: env,
      dsn: 'https://e7c4e4e46f8817cf761b0aeee90f3dc1@o4507450592133120.ingest.us.sentry.io/4507880992931840',
      release: `j_${process.env.BUILD_ID}`, // 使用 jenkins build id 作为版本
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.breadcrumbsIntegration({ console: false }),
        Sentry.replayIntegration({
          maskAllText: true, // 屏蔽文本
          blockAllMedia: true, // 屏蔽图片
        }),
        // TODO 适配 react
        // Sentry.reactRouterV6BrowserTracingIntegration({
        //   useEffect: React.useEffect,
        //   useLocation,
        //   useNavigationType,
        //   createRoutesFromChildren,
        //   matchRoutes,
        // }),
      ],
      // 指定环境地址进行上报
      denyUrls: ['localhost', '127.0.0.1'],
      // allowUrls: ['mtt.xyz'],
      tracesSampleRate: 1.0, // 100% 的采样率
      replaysSessionSampleRate: 0, // 非异常不采样重播
      replaysOnErrorSampleRate: 1.0, // 错误时的重播采样率
      ...options,
    };
    console.debug(`[sentry option]`, _options);
    Sentry.init(_options);
  }
};
